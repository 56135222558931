import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import React, { useEffect, useRef } from 'react';

const ArySelect = ({
  disabled,
  error,
  label,
  helperText,
  width,
  loading,
  children,
  required,
  focused,
  customLabel,
  ...others
}) => {
  const selectRef = useRef(null);

  useEffect(() => {
    if (focused && selectRef.current) {
      selectRef.current.focus();
    }
  }, [focused]);

  return (
    <FormControl
      disabled={disabled}
      error={error}
      sx={{ width: width || '100%' }}
    >
      <InputLabel sx={{ fontSize: '.9rem' }} id="select-label">
        {label}
      </InputLabel>
      <Select
        label={customLabel || `${label}${required ? ' *' : ''}`}
        labelId="select-label"
        inputRef={selectRef}
        {...others}
      >
        {loading ? (
          <MenuItem
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white !important',
              '&:hover': {
                backgroundColor: 'white !important'
              }
            }}
          >
            <CircularProgress style={{ width: '20px', height: '20px' }} />
          </MenuItem>
        ) : (
          children
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ArySelect;
